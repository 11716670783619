<template>
  <div>
    <TogglePanel
      :title="`${$tc('script', 2)} (${$tc('global', 1)})`"
      :icon="{
        before: 'fa fa-file-code-o',
        collapse: 'fa-caret-square-o-up',
        expand: 'fa-caret-square-o-down'
      }"
    >
      <div class="text-center">
        <ScriptsForm />
      </div>
    </TogglePanel>
    <TogglePanel
      :title="`${$tc('event', 2)} (${$tc('local', 1)})`"
      :icon="{
        before: 'fa fa-flash',
        collapse: 'fa-caret-square-o-up',
        expand: 'fa-caret-square-o-down'
      }"
    >
      <AlertForm
        v-model="confirmation"
        label="require_confirmation_when_exiting"
        text="you_have_unfinished_changes"
      >
        <template slot="before">
          <div class="form-group form-group-sm">
            <label for="">{{ $tc("trigger_condition", 1) }}</label>
            <select class="form-control" v-model="confirmationCondition">
              <option value="always">{{ $t("always") }}</option>
              <option value="pending_changes">{{
                $t("pending_changes")
              }}</option>
            </select>
          </div>
        </template>
      </AlertForm>

      <div>
        <ControlEventEditor
          :control="templateAsControl"
          @delEvent="onDelEvent($event)"
          @addEvent="onAddEvent($event)"
          style="margin: 10px -10px 0 -10px"
        />
      </div>
    </TogglePanel>
    <!-- <TogglePanel
      title="dataset"
      persistent="toggle_dashboard_dataset"
      :icon="{
        before: 'fa fa-database',
        collapse: 'fa-caret-square-o-up',
        expand: 'fa-caret-square-o-down'
      }"
    >
      <NamedQueriesForm />
    </TogglePanel> -->
  </div>
</template>

<script>
import Events from "@/assets/dashboard/events.json";
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
import ControlEventEditor from "@/components/editor/control-event-editor.vue";
import ScriptsForm from "@/components/control-sidebar/property-editors/scripts-form.vue";
// import NamedQueriesForm from "@/components/control-sidebar/property-editors/named-queries-form.vue";
import AlertForm from "@/components/editor/alert-form.vue";
export default {
  name: "DashboardEventsForm",
  components: {
    TogglePanel,
    ScriptsForm,
    ControlEventEditor,
    // NamedQueriesForm,
    AlertForm
  },
  data() {
    return {
      confirmation: null,
      confirmationCondition: "always"
    };
  },
  computed: {
    contract() {
      return this.$store.getters["user/contract"] || null;
    },
    draft() {
      return this.$store.getters["dashboard/draft"] || null;
    },
    template() {
      return this?.draft?.template;
    },
    screenId() {
      return this.draft?.screenId || null;
    },
    templateAsControl() {
      return this.template
        ? {
            id: this.screenId,
            componentName: "Screen",
            on: this.template?.on || null
          }
        : null;
    },
    confirmationPayload() {
      return this.confirmation
        ? {
            ...this.confirmation,
            condition: this.confirmationCondition
          }
        : null;
    }
  },
  watch: {
    confirmationPayload(n, o) {
      if (n == o) return;
      let template = {...this.template};
      template.on_leave_confirmation = n;
      this.saveDraft(template);
    }
  },
  methods: {
    saveDraft(template) {
      if (this.removing) return;
      this.$store.dispatch("dashboard/saveDraft", {
        screenId: this.screenId,
        template: template
      });
    },
    onAddEvent(name) {
      if (!this.template || typeof name !== "string") return;
      let evt = JSON.parse(JSON.stringify(Events[name]));
      let template = JSON.parse(JSON.stringify(this.template));
      template.on = template.on || {};
      template.on[name] = evt;
      this.saveDraft(template);
    },
    onDelEvent(name) {
      if (!this.template) return;
      let template = JSON.parse(JSON.stringify(this.template));
      template.on = template.on || {};
      this.$delete(template.on, name);
      this.saveDraft(template);
    }
  },
  created() {
    if (this?.template?.on_leave_confirmation) {
      this.confirmation = this.template.on_leave_confirmation;
      this.confirmationCondition = this.template.on_leave_confirmation.condition;
    }
  }
};
</script>

<style scoped></style>

<template>
  <div>
  <span @click.prevent="onClick()" :title="$tc('export')">
    <slot>
        <span class="btn btn-xs" v-if="!auto">
        <i class="fa fa-download"></i>
      </span>
    </slot>
  </span>

    <ModalExportScreen
      :modalOpen="modalOpen"
      :screenId="screenId"
      @hide="onModalExportHide"
      @close="onModalExportHide"
    />
  </div>
</template>

<script>
import DashboardPublisher from "@/components/editor/dashboard-publisher.vue";
import ModalExportScreen from "@/components/modal-export-screen.vue";

export default {
  name: "DashboardDownloadBtn",
  extends: DashboardPublisher,
  components: {
    ModalExportScreen
  },
  props: {
    screenId: {
      type: [Number, String],
      required: true,
      default: () => 0
    },
    auto: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      screen: null,
      modalOpen: false
    };
  },
  watch: {
    screenId: {
      handler(n) {
        if (this.auto && n) {
          if (this.$store.getters["dashboard/screen"](n)) {
            this.onClick();
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    onClick() {
      this.$nextTick(() => this.modalOpen = true);
    },
    onModalExportHide() {
      this.$nextTick(() => this.modalOpen = false);
    }
  }
}
</script>

<template>
  <div
    :class="[
      media == 'print' ? 'outer-panel-printout' : 'outer-panel',
      `panel-${panelTemplate}`
    ]"
  >
    <component
      v-if="can"
      :is="WIDGET"
      :style="panelStyle"
      :equipment="equipment"
      :display="display"
      :panelName="panelName"
      :screenId="screenId"
      :title="$t(panelTitle)"
      :panel="panel"
      :mode="mode"
      :template="template"
      :preview="preview"
      :class="`inner-panel __${panelName}__`"
      :isEditing="isEditing"
    >
      <template #toolbar>
        <PanelToolbarCustom :panel="panel" v-bind:equipment="equipment" />
      </template>
    </component>
    <EquipmentEmptyPanel v-else />
  </div>
</template>
<script>
const ComponentNotAvailable = () =>
  import("@/components/component-not-available.vue");
const EquipmentTableDetailsReport = () =>
  import("@/components/equipment-table-details-report.vue");
const EquipmentTableAlarmSummary = () =>
  import("@/components/equipment-table-alarm-summary.vue");
const EquipmentTableBasicReport = () =>
  import("@/components/equipment-table-basic-report.vue");
const EquipmentTableBasicInfo = () =>
  import("@/components/equipment-table-basic-info.vue");
const EquipmentTableDataView = () =>
  import("@/components/equipment-table-data-view");
const SynopticPanel = () => import("@/components/synoptic/synoptic-panel.vue");
const EquipmentDataPanel = () =>
  import("@/components/equipment-data-panel.vue");
const EquipmentHistoryPanel = () =>
  import("@/components/equipment-history-panel.vue");
const EquipmentAlarmPanel = () =>
  import("@/components/equipment-alarm-panel.vue");
const EquipmentEmptyPanel = () =>
  import("@/components/equipment-empty-panel.vue");
const EquipmentTableStationPanel = () =>
  import("@/components/equipment-table-station-panel.vue");
const EventsHistoryPanel = () =>
  import("@/components/events-history-panel.vue");
const EquipmentToolBarPanel = () =>
  import("@/components/equipment-toolbar-panel.vue");
const EquipmentHistoryChartPanel = () =>
  import("@/components/equipment-history-chart-panel.vue");
const DashboardTablePanel = () =>
  import("@/components/dashboard-table-panel.vue");
const DashboardRichTextPanel = () =>
  import("@/components/dashboard-rich-text-panel.vue");
const SynopticDisplay = () =>
  import("@/components/synoptic/synoptic-display.vue");
const EquipmentAlarmConfigurationDisplay = () =>
  import("@/components/equipment-alarm-configuration-display.vue");
const EquipmentAlarmHistoryDisplay = () =>
  import("@/components/equipment-alarm-history-display.vue");
const EquipmentHistoryChartDisplay = () =>
  import("@/components/equipment-history-chart-display.vue");
const EquipmentHistoryReportDisplay = () =>
  import("@/components/equipment-history-report-display.vue");
const DashboardImagePanel = () =>
  import("@/components/dashboard-image-panel.vue");
const DeviceListTablePanel = () =>
  import("@/components/device-list-table-panel.vue");
const EquipmentCardPanel = () =>
  import("@/components/equipment-card-panel.vue");
const EquipmentSearchPanel = () =>
  import("@/components/equipment-search-panel.vue");
const EquipmentCustomPanel = () =>
  import("@/components/equipment-custom-panel.vue");
const EquipmentExtendedPropertiesPanel = () =>
  import("@/components/equipment_extended_properties_panel.vue");

import InfoBox from "@/components/info-box.vue";

import MainForm from "@/components/control-sidebar/property-editors/main-form.vue";
// import PanelToolbarEditor from "@/components/panel-toolbar-editor.vue";
// import PanelToolbarViewer from "@/components/panel-toolbar-viewer.vue";
import PanelToolbarCustom from "@/components/panel-toolbar-custom.vue";
export default {
  name: "EquipmentDashboardPanel",
  components: {
    EquipmentTableDetailsReport,
    EquipmentTableDataView,
    SynopticPanel,
    EquipmentDataPanel,
    EquipmentHistoryPanel,
    EquipmentAlarmPanel,
    EquipmentEmptyPanel,
    SynopticDisplay,
    EquipmentAlarmConfigurationDisplay,
    EquipmentAlarmHistoryDisplay,
    EquipmentHistoryChartDisplay,
    EquipmentHistoryReportDisplay,
    EquipmentTableAlarmSummary,
    EquipmentTableBasicReport,
    EquipmentTableBasicInfo,
    EquipmentTableStationPanel,
    EventsHistoryPanel,
    EquipmentToolBarPanel,
    EquipmentHistoryChartPanel,
    DashboardTablePanel,
    DashboardRichTextPanel,
    DashboardImagePanel,
    DeviceListTablePanel,
    EquipmentCardPanel,
    EquipmentSearchPanel,
    EquipmentCustomPanel,
    EquipmentExtendedPropertiesPanel,
    InfoBox,
    // PanelToolbarEditor,
    // PanelToolbarViewer,
    PanelToolbarCustom
  },
  props: {
    equipment: {
      type: Object,
      required: false,
      default: () => ({})
    },
    display: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      default: "0"
    },
    screenId: {
      type: [String, Number],
      default: () => 0
    }
  },
  data() {
    return {
      small: false,
      customPanelProperties: null,
      busy: false
    };
  },
  computed: {
    mode() {
      return this.$route.path.startsWith("/dashboard/screen")
        ? "editor"
        : "viewer";
    },
    preview() {
      return this.$route.path.startsWith("/dashboard/screen/view");
    },
    can() {
      if (this.panel) {
        if (this.panel.rule) {
          return this.$can("manage", this.panel.rule);
        }
        return true;
      }
      return false;
    },
    panel() {
      return this.mode == "editor"
        ? this.editorPanel || this.connectorPanel
        : this.connectorPanel;
    },
    editorPanel() {
      if (this.mode != "editor") return null;
      let panel = this.$store.getters["dashboard/currentDraftPanel"] || null;
      if (panel && panel.name == this.name) {
        return panel;
      }
      let draft = this.$store.getters["dashboard/draft"] || null;
      return (
        (draft &&
          (draft?.template?.panels || []).find(
            ({name}) => name == this.name
          )) ||
        null
      );
    },
    connectorPanel() {
      return (
        (this?.display?.panels || []).find((i) => i.name == this.name) || null
      );
    },
    panelName() {
      var panel = this.panel;
      return panel.name || this.name;
    },
    panelStyle() {
      let style = {};
      let report =
        this.panel.template == "DashboardTablePanel" && this.media == "print";
      if (this.display.render_version) {
        style = {...(this?.panel?.style || {})};
        if (!style["background-color"]) {
          style["background-color"] = "#fff";
        }
        if (this.media == "print") {
          // print media does not treat device dimensions
          style["overflow-x"] = "unset";
          style["overflow-y"] = "unset";
          style["min-height"] =
            (this?.panel?.style || {})["min-height"] || "auto";
          if (report) {
            style["max-height"] = "auto";
          }
        } else {
          style["overflow-x"] =
            (this?.panel?.style || {})["overflow-x"] || "hidden";
          style["overflow-y"] =
            (this?.panel?.style || {})["overflow-y"] || "hidden";
        }
      } else {
        style = JSON.parse(JSON.stringify(this?.panel?.style || {}));
      }
      if (this.small) {
        // panels are piled up vertically and not side by side horizontally
        if ("min-height" in style) {
          let height = parseInt((style["min-height"] + "").replace(/px/, ""));
          if (window.innerHeight > height) {
            // expand till the device height
            style["min-height"] = "100vh";
            style["max-height"] = "100vh";
          } else {
            // remove limitation
            delete style["min-height"];
            delete style["max-height"];
          }
        }
      } else {
        // make it compatible with old screens
        if ("min-height" in (this?.panel?.style || {})) {
          style["min-height"] = this?.panel?.style["min-height"];
        }
        if ("max-height" in (this?.panel?.style || {}) && !report) {
          style["max-height"] = this?.panel?.style["max-height"];
        }
        if ("min-height" in style && !("background-color" in style)) {
          style["background-color"] = "#ffffff";
        }
      }
      if (this.panel.template == "SynopticPanel") {
        // synoptic panel calculates it by its own
        if (this.isEditing && this.$store.getters["dashboard/expandedPanel"]) {
          if (this.media == "screen") {
            delete style["min-height"];
          }
          delete style["max-height"];
        }
      }
      style.opacity = this.busy ? ".6" : "1";
      style.cursor = this.busy ? "wait" : "default";
      return style;
    },
    panelTitle() {
      let result = this?.panel?.title
        ? this.$utils.evaluate(
            {
              connector: this.equipment || {}
            },
            this?.panel?.title
          )
        : null;
      return (
        (typeof result == "string" ? result : "") || this?.panel?.title || ""
      );
    },
    WIDGET() {
      var components = this.$options.components || {};
      var panel = this.panel;
      var widget = (panel && panel.template) || "ComponentNotAvailable";
      return (
        (widget && widget in components && components[widget]) ||
        ComponentNotAvailable
      );
    },
    isEditing() {
      return this.editorEditPath == this.$route.path;
    },
    template() {
      return this.$store.getters["dashboard/template"](this.screenId);
    },
    editorEditPath() {
      return `/dashboard/screen/edit/${this.screenId}/${this.panelName}`;
    },
    editorViewPath() {
      return `/dashboard/screen/view/${this.screenId}`;
    },
    media() {
      if (this.$store.getters["dashboard/mode"] == "editor") return "screen";
      return this?.$route?.query?.media || this?.template?.media || "screen";
    },
    panelTemplate() {
      return this?.panel?.template ?? "";
    }
  },
  methods: {
    updateSize() {
      this.small = window.innerWidth < 768;
    }
  },
  mounted() {
    this.$emit("mounted", this.panel.name);
  }
};
</script>

<style scoped>
.outer-panel {
  width: 100%;
  max-width: 100vw;
  display: inline-block;
  vertical-align: top;
  padding-bottom: 10px;
  min-height: 64px;
}

.outer-panel-printout {
  width: 100%;
  /* max-width: 100vw; */
  display: inline-block;
  vertical-align: top;
  /* padding-bottom: 10px; */
}

.inner-panel {
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
  /* box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1); */
  border-radius: 5px;
}

@media (max-width: 767px) {
  .panel-EquipmentEmptyPanel {
    display: none !important;
  }
}

div.box-header::v-deep {
  display: flex;
}
</style>
<style>
.lightup-shadow {
  animation: lightUpShadow 1s 0.3s ease forwards;
}

@keyframes lightUpShadow {
  0%,
  100% {
    box-shadow: none;
  }

  50% {
    box-shadow: 0 0 6px 2px rgb(60 141 188 / 45%);
  }
}
</style>

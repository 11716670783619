<template>
  <div>
    <div class="form-group text-left" v-if="entry === ''">
      <label for="">HTML {{ $t("model") }}</label>
      <select name="" id="" class="form-control" v-model="selected">
        <option value="">{{ $t("select") }}</option>
        <option
          :value="item.name"
          :title="item.description"
          v-for="item in list"
          :key="item.name"
          >{{ $t(item.title) }}</option
        >
      </select>
    </div>
    <div class="row" v-else>
      <div class="col-md-6">
        <button
          class="btn btn-sm btn-default"
          :disabled="isOpen"
          @click.stop.prevent="entry = ''"
        >
          <i class="fa fa-trash"></i>
          {{ $t("delete") }}
        </button>
      </div>
      <div class="col-md-6">
        <button
          class="btn btn-sm btn-primary"
          @click.stop.prevent="isOpen = true"
          :disabled="isOpen"
        >
          <i class="fa fa-pencil"></i>
          {{ $t("edit") }}
        </button>
      </div>
    </div>
    <HTMLEditor
      v-if="isOpen && ready"
      @open="open"
      @close="close"
      v-model="entry"
    >
    </HTMLEditor>
  </div>
</template>
<script>
import HTMLEditor from "@/components/editor/js-script-editor/html-editor.vue";
// import A4 from "/static/common/wrappers/a4.json" with { type: "json" };
const dftEntry = () => ({
  html: "",
  style: "",
  css_vars: "",
  javascript: ""
});
export default {
  name: "HTMLForm",
  props: {
    value: {
      type: Object,
      default: null,
      required: false
    }
  },
  components: {
    HTMLEditor
  },
  data() {
    return {
      ready: false,
      isOpen: false,
      iValue: "",
      list: [],
      iSelected: ""
    };
  },
  computed: {
    contract() {
      return this.$store.getters["user/contract"] || null;
    },
    scriptList() {
      return this.$store.getters["dashboard/scriptList"] || [];
    },
    status() {
      return this.$store.getters["scripts/status"];
    },
    entry: {
      set(value) {
        this.iValue = value;
        this.$emit("input", value !== "" ? JSON.parse(this.iValue) : null);
        if (value === "") {
          this.iSelected = "";
        }
      },
      get() {
        return this.iValue;
      }
    },
    selected: {
      set(value) {
        this.iSelected = value;
        if (value === "") {
          this.entry = "";
          return;
        }
        // if (value == "new") {
        //   this.entry = JSON.stringify(dftEntry());
        //   this.isOpen = true;
        //   return;
        // }
        let item = this.list.find(({name}) => name == value);
        if (item) {
          this.$http.get(item.template_path).then((res) => {
            if (res.status == 200 && res.bodyText) {
              this.entry = res.bodyText;
              this.isOpen = true;
            }
          });
        }
      },
      get() {
        return this.iSelected;
      }
    }
  },
  watch: {
    scope(n) {
      this.ready = false;
      this.$nextTick(() => {
        this.ready = true;
      });
    }
  },
  methods: {
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    }
  },
  created() {
    this.ready = true;
    // this.iValue = this.value ? JSON.stringify(this.value) : "";
    this.iValue = this.value ? JSON.stringify(this.value) : "";
  },
  beforeCreate() {
    this.$http.get("/static/common/wrappers/index.json").then((res) => {
      if (res.status == 200 && res.body) {
        this.list = res.body;
      }
    });
  },
  beforeDestroy() {}
};
</script>

<style scoped>
.js-toolbar-button {
  margin: 0 10px;
}
.form-inline > div {
  margin-top: -5px;
  margin-right: 10px;
  vertical-align: middle;
}

.form-inline > div > select {
  padding: 0 10px;
  line-height: 22px;
  height: 22px;
}
</style>
